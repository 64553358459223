<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                携手海通证券，畅谈数智交易行业发展趋势及未来合作机会
              </p>
              <div class="fst-italic mb-2">2023年12月21日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >海通证券</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/68/01-主题.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    近日，非凸科技携手海通证券红宝石路证券营业部以“科技赋能投资”为主题，在上海举办了一场交流分享活动，与管理人、资方共同探讨了数智交易行业发展趋势及未来合作机会。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/68/02-徐晓啸.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    作为行业数字化转型的先行者和探索者，海通证券一直秉持“科技引领”战略，始终保持着对金融科技的战略性投入，加速把金融科技的技术优势转化为数字化优势，率先进入数字化转型深水区，以期为行业高质量发展作出新贡献。海通证券数字金融部机构平台部经理徐晓啸以私募交易系统需求的发展、私募业务需求的演变作为切入点，详细介绍了海通证券在陪伴私募客户成长过程中做出的交易服务支持。他表示，海通证券建设算法交易系统，旨在集成各类算法功能，通过e海方舟机构交易平台对客户实现统一服务，并通过与算法供应商多方合作，搭建全面契合市场投资者的交易平台。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/68/03-席沁昀.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    海通证券红宝石路证券营业部是海通证券倾⼒打造的创新型营业部，主要为财富管理、企业经营、家族治理等全⽅位需求提供⼀站式综合解决⽅案。海通证券红宝石路营业部负责人席沁昀表示，红宝石路证券营业部也将作为链接高净值客户财富管理的需求以及私募管理人优质策略的平台，将资金端和资产端更高效、更全面地整合在一起。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/68/04-非凸.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    活动上，非凸科技详细介绍了非凸算法业务场景及服务解决方案。随着国内数智交易不断走向成熟，数字化、智能化正成为主流，算法交易的优势逐步放大，并成为机构投资者更为关注的部分。未来，数智交易行业将会呈现出百花齐放的格局，而非凸科技也将保持初心，磨练研发技能，精进技术细节，为客户提供更好的科技工具，助力其提升机构服务竞争力，实现业务快速发展。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/68/05-现场.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    交流环节，管理人与资方分享了各自独特的交易策略及产品，为与会者提供了深刻的行业洞察。此次交流，不仅丰富了大家对数智投资的深入理解，还为未来合作奠定了坚实的基础。通过分享实践案例和创新思路，与会者们相互启发，为推动行业发展贡献了积极力量。
                  </p>
                  <p>
                    洞察行业暗涌，把握时代脉搏。未来，非凸科技将携手行业生态合作伙伴，以科技自主创新为抓手，持续探索前沿科技与金融行业的创新融合，进而推动数智交易行业高质量发展。
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News68",
};
</script>

<style></style>
